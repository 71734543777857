<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <header-image :title="headerTitle"></header-image>
    <!-- /头部 -->

    <!-- 体部 -->
    <div style="display: flex;justify-content: center;margin-top: 30px">
      <div>
        <div class="el-table" >
          <el-table class="exporttable"
                  :data="tableData"
                  border
                    header-cell-style="background-color: #1abc9c; color: white;borderColor:#000000"
                    :cell-style="{borderColor:'#000000'}"
                    >
            <el-table-column
                    prop="name"
                    label="使用版本"
                    width="450"
                    align="center"
            >
            </el-table-column>
            <el-table-column
                    prop="address"
                    label="收费标准"
                    width="250"
                    align="center">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>


    <!-- /体部 -->

    <!-- 尾部 -->
    <Footer></Footer>
    <!-- /尾部 -->

  </div>
</template>
 
<script>
import { get, post } from "@/utils/request";
import Carousel from "../components/Carousel";
  export default {
    name: "index",
    components: {},
    data() {
      return {
        headerTitle:"收费标准",
        tableData: [{
          name: '专业版（11-30人）',
          address: '¥368/年'
        }, {
          name: '通用版(10人以内)',
          address: '¥299/年'
        }, {
          name: '旗舰版（31-50人)',
          address: '¥599/年'
        }, {
          name: '定制版（>50人）',
          address: '¥1099/年'
        }]
    };
  },
  created() {},
  methods: {},

};
</script>

<style lang='scss'>
  // 体部样式
  .el-table{
      width: 700px;
      height: 243px;

      .exporttable {
          border: solid 1px #000000;
      }

  }

</style>